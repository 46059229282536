* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #a3c3d8;
  color: #222;
}

.counter {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 20px;
}

.item {
  background: #f7f6f4;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
}

.itemDetail {
  background: #f7f6f4;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
}

.item img {
  border-radius: 1rem;
  width: 100%;
  object-fit: fill;
  pointer-events: none;
}

.inventorylist {
  width: 90vw;
  max-width: 1170px;
  margin: 5rem auto;
  display: grid;
  gap: 2rem;
}

@media screen and (max-width: 600px) {
  .inventorylist {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .inventorylist {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 901px) {
  .inventorylist {
    grid-template-columns: repeat(3, 1fr);
  }
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.navbar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.navbar li {
  float: left;
}

.navbar li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar li a:hover {
  background-color: #111;
}

form {
  margin-bottom: 1em;
}

input {
  margin-right: 0.5em;
}

#addItemForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 400px;
  padding: 20px;
  background-color: burlywood;
  border-radius: 1rem;
}

#addItemForm > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#addItemForm input[type="text"],
#addItemForm input[type="number"],
#addItemForm input[type="file"],
#addItemForm button {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

#addItemForm button {
  background-color: #333;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

#addItemForm button:hover {
  background-color: #555;
}
